

const themeArray = [
    'green',
    'blue',
    'black',
    'white',

]

export { themeArray }



